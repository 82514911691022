.react-datepicker {
    border: 0;
    font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
      Arial, sans-serif;
    box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.1), 0px 16px 48px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    z-index: 100;
  }
  .react-datepicker__triangle {
    display: none !important;
  }
  .react-datepicker__header {
    background: #f4f4f5;
    border-bottom: 0;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__day--selected:hover {
    background-color: #000;
  }
  
  [data-color-scheme="dark"] .react-datepicker {
    background-color: #000;
    box-shadow: 0px 0px 2px rgba(255, 255, 255, 0.5),
      0px 16px 48px rgba(255, 255, 255, 0.2);
  }
  
  [data-color-scheme="dark"] .react-datepicker__header,
  [data-color-scheme="dark"]
    .react-datepicker__time-container
    .react-datepicker__time {
    background: #18181b;
  }
  .react-datepicker__time-list-item {
    border-radius: 16px;
  }
  [data-color-scheme="dark"] .react-datepicker__current-month,
  [data-color-scheme="dark"] .react-datepicker__day--today,
  [data-color-scheme="dark"] .react-datepicker__day,
  [data-color-scheme="dark"] .react-datepicker-time__header,
  [data-color-scheme="dark"] .react-datepicker__time-list-item {
    color: #fff;
  }
  [data-color-scheme="dark"] .react-datepicker__day--selected {
    background-color: #fff;
    color: #000;
  }
  [data-color-scheme="dark"] .react-datepicker__day-name {
    color: #fff;
  }
  [data-color-scheme="dark"] .react-datepicker__day--disabled {
    color: #71717a;
  }
  [data-color-scheme="dark"] .react-datepicker__day:hover,
  [data-color-scheme="dark"] .react-datepicker__month-text:hover,
  [data-color-scheme="dark"] .react-datepicker__quarter-text:hover,
  [data-color-scheme="dark"] .react-datepicker__year-text:hover,
  [data-color-scheme="dark"]
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    background-color: #3f3f46;
    color: #fff;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background-color: #000;
  }
  [data-color-scheme="dark"] .react-datepicker__time-container {
    border-left-color: #000;
  }
  [data-color-scheme="dark"] .react-datepicker__day--selected:hover {
    background-color: #fff;
    color: #000;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #000;
    color: #fff;
  }
  [data-color-scheme="dark"]
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #fff;
    color: #000;
  }
  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 0;
  }
  .react-datepicker__month-container {
    float: none;
  }
  .react-datepicker__time-container {
    float: none;
    width: 100%;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 240px;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    display: flex;
    height: 50px !important;
    padding-top: 10px;
    overflow: auto hidden;
  }

  .react-datepicker-popper {  z-index: 100 !important }

  .react-datepicker__input-container {
    visibility: hidden;
  }
  
  /* Custom Time */
  
  .react-datepicker-time__caption {
    font-weight: 500;
  }
  
  [data-color-scheme="dark"] .react-datepicker-time__caption {
    color: #fff;
  }
  [data-color-scheme="dark"]
    .react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input
    input[type="time"] {
    background-color: #000;
    color: #fff;
  }
  